import React from 'react';
import { Spin } from 'antd';
import './LoaderOverlay.scss';

interface LoaderOverlayProps {
    label?: string;
    size?: string;
    loading?: boolean;
    placement?: 'top' | 'middle';
}

export default React.memo(function LoaderOverlay({
    label,
    size,
    loading,
    placement
}: LoaderOverlayProps) {
    return !loading ? null : (
        <div className={`loader-overlay --${placement || 'middle'}`}>
            <Spin />
        </div>
    );
});
