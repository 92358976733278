const clientTypeLabelMap: { [type: string]: string } = {
    senior: 'Senior',
    company: 'Entreprise',
    active: 'Actif',
};

export default class ClientTypeService {
    static getClientType(dishType: string): string {
        return (
            clientTypeLabelMap[dishType.toLowerCase()] ||
            clientTypeLabelMap.active
        );
    }
    static getClientTypes() {
        return Object.keys(clientTypeLabelMap);
    }
}
