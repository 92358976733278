import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

import SignUpForm from './SignUpForm';

import './Login.scss';
import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';
import { LeftOutlined } from '@ant-design/icons';

import CGUModal from 'views/login/CGUModal';

import './SignUp.scss';

export default function SignUp() {
    const [fields, setFields] = useState([] as any[]);
    const [values, setValues] = useState([] as any[]);
    const [cguModalVisible, setCguModalVisible] = useState<boolean>(false);

    function handleSubmit (values: any) {
        setValues(values);
        setCguModalVisible(true);
    }
    
    return (
        <div className="sign-up">
            <div className="__header">
                <div className="__logo">
                    <img
                        className="__icon"
                        src={logoIcon}
                        alt="Logo"
                    />
                    <img
                        className="__text"
                        src={logoText}
                        alt="La Cigale"
                    />
                </div>
                <div className="__catch-phrase">
                    <strong>Livraison de repas</strong> sur mesure à
                    domicile depuis <strong>1985</strong> sur{' '}
                    <strong>Nîmes</strong> et ses environs.
                </div>
                <a
                    href="https://repaslacigale.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        className="__more-info-btn"
                        ghost
                        shape="round"
                        size="large"
                    >
                        En savoir plus
                    </Button>
                </a>
            </div>
            <div className="__content">
                <h1>Inscrivez-vous</h1>
                <SignUpForm
                    onChange={setFields}
                    fields={fields}
                    onSignUp={handleSubmit}
                />
                <Link to="/login">
                    <Button
                        type="link"
                        shape="round"
                        size="large"
                    >
                    <LeftOutlined /> Retour
                    </Button>
                </Link>
            </div>
            {values && 
                <CGUModal
                    visible={cguModalVisible}
                    onClose={() => setCguModalVisible(false)}
                    values={values}
                />
            }
        </div>
    )
}
