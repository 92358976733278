import React, { useState, useEffect, useContext } from 'react';
import { Modal, notification, Button } from 'antd';
import { Redirect, useHistory } from 'react-router-dom';

import LoaderOverlay from 'shared/components/LoaderOverlay';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';
import Parameters from 'shared/parameters';
import { AuthContext } from 'shared/contexts/AuthContext';

interface CGUModalProps {
    visible: boolean;
    onClose: () => void;
    values: any;
}

export default function CGUModal({ visible, onClose, values }: CGUModalProps) {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [gdpr, setGdpr] = useState<string | undefined>(undefined);
    const history = useHistory();
    const { isAuth, updateAuthToken } = useContext(AuthContext);

    useEffect(() => {
        fetch(`${BaseUrlConstants.BASE_URL}gdpr`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res: any) => {
                if (!res.ok) {
                    throw Error(res.statusText);
                } else {
                    return res;
                }
            })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setGdpr(data.content);
            })
            .catch((error: string) => {
                console.error(error);
                setLoading(false);
                notification.error({
                    message: 'Une erreur est survenue',
                });
            });
    });

    function handleSubmit() {
        setFormLoading(true);
        delete values.confirmPassword;
        fetch(`${BaseUrlConstants.BASE_URL}create-client`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                input: values,
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res.statusText);
                }
                return res;
            })
            .then((res) => res.json())
            .then(() => {
                fetch(`${Parameters.ApiUrl}/auth-tokens`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        login: values.email,
                        password: values.plainPassword,
                    }),
                })
                    .then((res) => {
                        if (!res.ok) {
                            throw Error(res.statusText);
                        }
                        return res;
                    })
                    .then((res) => res.json())
                    .then((data) => {
                        updateAuthToken(data);
                    })
                    .catch(() => {
                        history.push({
                            pathname: '/login',
                        });
                    });

                notification.success({
                    message: 'Votre inscription a été enregistré',
                });
            })
            .catch((error: string) => {
                console.error(error);
                notification.error({
                    message: "Erreur lors de l'enregistrement",
                });
            })
            .finally(() => {
                setFormLoading(false);
            });
    }

    if (isAuth) {
        return <Redirect to="/" />;
    }

    return (
        <Modal
            className="cgu-modal"
            title="Conditions générales d'utilisation"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            {loading && <LoaderOverlay loading />}
            <p>{gdpr}</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={formLoading}
                    shape="round"
                    size="large"
                    onClick={handleSubmit}
                    style={{ margin: '10px' }}
                >
                    J'ai lu et j'accepte les CGU
                </Button>
                <Button
                    type="link"
                    shape="round"
                    size="large"
                    onClick={onClose}
                >
                    Annuler
                </Button>
            </div>
        </Modal>
    );
}
