import React, { lazy, ReactElement } from 'react';
import { useMedia } from 'react-use';
import {
    BarChartOutlined,
    UserOutlined,
    HomeOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { useLocation, useHistory, Route, Switch } from 'react-router-dom';

import DesktopLayout from 'shared/components/layout/DesktopLayout';
import MobileLayout from 'shared/components/layout/MobileLayout';

const Home = lazy(() => import("views/Home"));
const OrderList = lazy(() => import("views/OrderList"));
const CommandScreen = lazy(() => import("shared/components/CommandScreen"));
const MealMenus = lazy(() => import("views/menus/MealMenus"));
const MyProfile = lazy(() => import("views/profile/MyProfile"));
const Parameters = lazy(() => import("views/parameters/Parameters"));

interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
}

export default function AppLayout() {
    const { pathname } = useLocation();
    const history = useHistory();
    const isMobile = useMedia('(max-width: 800px)');

    const menuItems: MenuItem[] = [
        {
            route: '/',
            icon: <HomeOutlined />,
            label: 'Accueil',
        },
        {
            route: '/menus',
            icon: <BarChartOutlined />,
            label: 'Menus',
        },
        {
            route: '/commandes',
            icon: <BarChartOutlined />,
            label: 'Commandes',
        },
        {
            route: '/mon-profil',
            icon: <UserOutlined />,
            label: 'Mon profil',
        },
        {
            route: '/parameters',
            icon: <SettingOutlined />,
            label: 'Paramètres',
        },
    ];
    const selectedMenu = pathname;
    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout = isMobile ? MobileLayout : DesktopLayout;

    return (
        <AppLayout
            menuItems={menuItems}
            selectedMenu={selectedMenu}
            onMenuClick={handleMenuClick}
        >
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/commandes" component={OrderList} />
                <Route exact path="/commandes/:commandId" component={CommandScreen} />
                <Route exact path="/commandes/new" component={CommandScreen} />
                <Route exact path="/menus" component={MealMenus} />
                <Route exact path="/mon-profil" component={MyProfile} />
                <Route exact path="/parameters" component={Parameters} />
            </Switch>
        </AppLayout>
    );
}
