import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, notification } from 'antd';

import ClientTypeService from 'shared/services/ClientTypeService';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';
import LoaderOverlay from 'shared/components/LoaderOverlay';

interface Props {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSignUp: (values: any) => void;
}

export default function SignUpForm({ fields, onChange, onSignUp }: Props) {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();
    const [loading, setLoading] = useState<boolean>(true);
    const [clientTypes, setClientTypes] = useState<Array<string>>([]);

    useEffect(() => {
        forceUpdate({});
        fetch(`${BaseUrlConstants.BASE_URL}client-types`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        })
            .then((res: any) => {
                if (!res.ok) {
                    throw Error(res.statusText);
                } else {
                    return res;
                }
            })
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setClientTypes(Object.values(data));
            })
            .catch((error: string) => {
                console.error(error);
                setLoading(false);
                notification.error({
                    message: 'Une erreur est survenue',
                });
            });
    }, []);

    return (
        <Form
            layout="vertical"
            hideRequiredMark
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(_, allFields) => {
                onChange(allFields);
            }}
            onFinish={onSignUp}
        >
            {loading && <LoaderOverlay loading />}
            <Form.Item
                name="firstName"
                label="Prénom"
                rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Nom"
                rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
                <Select>
                    {clientTypes.map((type: string) => (
                        <Select.Option key={type} value={type}>
                            {ClientTypeService.getClientType(type)}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="email"
                label="Email"
                rules={[
                    { required: true, message: 'Ce champ est requis' },
                    {
                        type: 'email',
                        message: "Cet email n'est pas valide",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label="Téléphone"
                rules={[{ required: true, message: 'Ce champ est requis' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="plainPassword"
                label="Mot de passe"
                rules={[
                    {
                        required: true,
                        message: 'Renseignez votre mot de passe',
                    },
                ]}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label="Répétez le mot de passe"
                rules={[
                    {
                        required: true,
                        message: 'Ce champ est requis',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (
                                !value ||
                                getFieldValue('plainPassword') === value
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                'Les mots de passe ne correspondent pas.',
                            );
                        },
                    }),
                ]}
            >
                <Input type="password" />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    style={{ marginTop: 16 }}
                >
                    S'inscrire
                </Button>
            </Form.Item>
        </Form>
    );
}
