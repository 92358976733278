import React, { useState, useCallback, useEffect } from 'react';
import { notification } from 'antd';
import Parameters from '../parameters';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

let AUTH_TOKEN: any = null;
let listener: any = null;

interface AuthToken {
    user: User;
    value: string;
}
interface User {
    id: number;
    isSuperAdmin: boolean;
    firstName: string;
    lastName: string;
    userType: string;
    roles: string[];
    shouldUpdatePassword: boolean;
}
interface AuthContextProps {
    authToken: AuthToken | null;
    user: User | undefined;
    isAuth: boolean;
    updateAuthToken: (authToken: AuthToken | null) => void;
}

const GET_USER = gql`
    query user($id: Int!) {
        user(id: $id) {
            id
            firstName
            lastName
            username
            email
            phoneNumber
            enabled
            roles
            isSuperAdmin
            userType
            shouldUpdatePassword
        }
    }
`;

const AuthContext = React.createContext<AuthContextProps>({
    authToken: null,
    user: undefined,
    isAuth: false,
    updateAuthToken: (authToken) => {},
});

function AuthProvider({ children }: any) {
    const [authToken, setAuthToken] = useState(() => {
        const authTokenStr = localStorage.getItem('auth-token');
        const authToken = authTokenStr ? JSON.parse(authTokenStr) : undefined;
        AUTH_TOKEN = authToken;
        return authToken;
    });
    const [user, setUser] = useState<User | undefined>(
        authToken && authToken.user,
    );

    const { data, refetch } = useQuery(GET_USER, {
        variables: {
            id: authToken ? authToken.user.id : null,
        },
    });

    useEffect(() => {
        if (data && data.user) {
            setUser(data.user);
        }
    }, [data]);

    const updateAuthToken = useCallback((authToken: any) => {
        if (authToken) {
            localStorage.setItem('auth-token', JSON.stringify(authToken));
        } else {
            localStorage.removeItem('auth-token');
        }

        AUTH_TOKEN = authToken;
        setAuthToken(authToken);
    }, []);

    useEffect(() => {
        function forceLogout() {
            notification.error({
                message: 'Vous avez été déconnecté',
            });
            updateAuthToken(null);
        }

        function checkToken() {
            fetch(`${Parameters.ApiUrl}/auth-tokens/check`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'X-AUTH-TOKEN': authToken.value,
                },
            })
                .then((res) => {
                    if (!res.ok) {
                        forceLogout();
                    }
                    refetch();
                })
                .catch(forceLogout);
        }
        if (authToken) {
            const intervalId = setInterval(checkToken, 2 * 60000);
            checkToken();

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [authToken]);

    return (
        <AuthContext.Provider
            value={{ updateAuthToken, authToken, user, isAuth: !!authToken }}
        >
            {children}
        </AuthContext.Provider>
    );
}

const AuthConsumer = AuthContext.Consumer;

/**
 * We use this function to be able to access
 * the auth token from outside React components.
 */
function getAuthToken() {
    return AUTH_TOKEN;
}

function getUrlFormatedToken() {
    const token = getAuthToken();
    return token ? encodeURIComponent(token.value) : '';
}

function logout() {
    if (listener != null) {
        listener(null);
    }
    notification.error({ message: 'You are logged out' });
}

export {
    AuthProvider,
    AuthConsumer,
    AuthContext,
    getAuthToken,
    getUrlFormatedToken,
    logout,
};
