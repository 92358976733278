import React, { useState, useEffect } from 'react';
import { Button, notification } from 'antd';

import { Link } from 'react-router-dom';
import logoIcon from 'shared/images/logo_white.svg';
import logoText from 'shared/images/text_white.svg';

import { ToastActions } from 'ias-lib';

import './ResetPassword.scss';
import BaseUrlConstants from 'shared/constants/BaseUrlConstants';
import LoaderOverlay from 'shared/components/LoaderOverlay';

declare interface ConfirmEmailProps {
    location: any;
}

function getUrlParameter(search: string, name: string) {
    const n = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${n}=([^&#]*)`);
    const results = regex.exec(search);
    return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
}
  
export default function ConfirmEmail({ location }: ConfirmEmailProps) {
    const email = getUrlParameter(location.search, "email");
    const [loading, setLoading] = useState<boolean>(true);
    const [displayEndMessage, setDisplayEndMessage] = useState<boolean>(false);

    useEffect(() => {
        fetch(`${BaseUrlConstants.BASE_URL}confirm-email`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
            })
        },
        ).then((res: any) => {
            if(res.ok) {
                setDisplayEndMessage(true);
            } else {
                notification.error({
                    message: "Une erreur est survenue",
                });
            }
            setLoading(false);
        }).catch((error: string) => {
            console.error(error);
            setLoading(false);
            ToastActions.createToastError(
                'Une erreur est survenue',
            )});
    })

    return (
        <div className="reset-password">
            <div className="__header">
                <div className="__logo">
                    <img
                        className="__icon"
                        src={logoIcon}
                        alt="Logo"
                    />
                    <img
                        className="__text"
                        src={logoText}
                        alt="La Cigale"
                    />
                </div>
                <div className="__catch-phrase">
                    <strong>Livraison de repas</strong> sur mesure à
                    domicile depuis <strong>1985</strong> sur{' '}
                    <strong>Nîmes</strong> et ses environs.
                </div>
                <a
                    href="https://repaslacigale.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button
                        className="__more-info-btn"
                        ghost
                        shape="round"
                        size="large"
                    >
                        En savoir plus
                    </Button>
                </a>
            </div>
            <div className="__content">
                {loading && <LoaderOverlay loading />}
                <h1>CONFIRMATION DE MON ADRESSE MAIL</h1>
                {displayEndMessage && <b>Votre adresse mail a été confimé, vous pouvez désormais vous connecter à votre compte client.</b>}
                <Link to="/login">
                    <Button
                        type="primary"
                        htmlType="submit"
                        shape="round"
                        style={{marginTop: "10px"}}
                    >
                       Se connecter
                    </Button>
                </Link>
            </div>
        </div>
    );
}
