import React from "react";

import { ConfigProvider } from "antd";

// Apollo
import { ApolloProvider } from '@apollo/react-hooks';
import {client} from 'shared/services/ApolloClient';

import frFR from 'antd/es/locale/fr_FR';

import "antd/dist/antd.css";
import "./App.scss";

// Router
import ProtectedRoute from 'shared/components/ProtectedRoute';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "shared/contexts/AuthContext";

// Views
import Layout from "views/Layout";
import Login from "views/login/Login";
import ConfirmEmail from "shared/components/ConfirmEmail";
import ResetPasswordRequest from "shared/components/ResetPasswordRequest";
import SignUp from "views/login/SignUp";

// moment
import moment from 'moment';
import 'moment/locale/fr'
moment.locale("fr");

export default function App() {
  return (
    <ApolloProvider client={client}>
      <ConfigProvider locale={frFR}>
          <AuthProvider>
              <Router>
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/confirm-email" component={ConfirmEmail} />
                  <Route exact path="/reset-password-request" component={ResetPasswordRequest} />
                  <Route exact path="/sign-up" component={SignUp} />
                  <ProtectedRoute path="/" component={Layout} />
                </Switch>
              </Router>
          </AuthProvider>
      </ConfigProvider>
    </ApolloProvider>
  );
}