import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';

declare interface ResetPasswordRequestFormProps {
    fields: any[];
    onChange: (fields: any[]) => void;
    onSubmit: (email: string) => void;
    loading: boolean;
}

export default function ResetPasswordRequestForm({ fields, onChange, onSubmit, loading }: ResetPasswordRequestFormProps) {
    const [form] = Form.useForm();
    const [, forceUpdate] = useState();

    useEffect(() => {
        forceUpdate({});
    }, []);

    function handleSubmit(values: any) {
        if (onSubmit) {
            const { email } = values;
            onSubmit(email);
        }
    }

    return (
        <Form
            size="large"
            form={form}
            fields={fields}
            onFieldsChange={(changedFields, allFields) => {
                onChange(allFields);
            }}
            onFinish={handleSubmit}
        >
            Votre adresse email
            <Form.Item
                name="email"
                rules={[
                    { required: true, message: 'Ce champ est requis' },
                    {
                        type: 'email',
                        message: "Cet email n'est pas valide",
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    shape="round"
                >
                    Réinitialiser mon mot de passe
                </Button>
            </Form.Item>
        </Form>
    );
}